import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";

import Table from "./Table";
import api from "../../../service/api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  textfield: {
    width: 80,
    paddingRight: 20,
  },
}));

export default function CheckboxListSecondary(props) {
  const { dados } = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [open, setOpen] = React.useState({});
  const [products, setProducts] = React.useState([]);

  const handleClick = (id) => {
    setOpen({ ...open, [id]: !open[id] });
  };

  const fetchProducts = (filter) => {
    filter = filter.join(",");
    api
      .post("/service.php", { request: "getProducts", filter: filter })
      .then((res) => setProducts(res.data))
      .catch((err) => console.log(err.message));
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    fetchProducts(newChecked);
    setChecked(newChecked);
  };

  const typeI = (dado) =>
    dado.Valores.map((valor) => {
      const labelId = `checkbox-list-label-${valor.Id}`;
      return (
        <ListItem
          button
          className={classes.nested}
          onClick={() => handleToggle(valor.Id)}
        >
          <ListItemText primary={valor.Descricao} />
          <Checkbox
            edge="start"
            checked={checked.indexOf(valor.Id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
          />
        </ListItem>
      );
    });

  const typeN = (dado) => {
    return (
      <ListItem
        button
        className={classes.nested}
        onClick={() => handleToggle(dado.Id)}
      >
        <TextField
          className={classes.textfield}
          id={dado.Id}
          label="Min"
          variant="outlined"
          placeholder={dado.Valor_Min}
        />
        <TextField
          className={classes.textfield}
          id={dado.Id}
          label="Max"
          variant="outlined"
          placeholder={dado.Valor_Max}
        />
        <Button variant="contained" color="primary">
          Filtrar
        </Button>
      </ListItem>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ padding: 4 }}>
        <List dense className={classes.root}>
          {dados.map((dado) => {
            const labelId = `checkbox-list-secondary-label-${dado.Id}`;
            return (
              <>
                <ListItem button onClick={() => handleClick(dado.Id)}>
                  <ListItemText primary={dado.Descricao} />
                  {open[dado.Id] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={open[dado.Id]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {dado.Tipo === "I" ? typeI(dado) : null}
                    {dado.Tipo === "N" ? typeN(dado) : null}
                  </List>
                </Collapse>
              </>
            );
          })}
        </List>
      </div>
      <div style={{ padding: 4 }}>
        <Table rows={products} handleAddProduct={props.handleAddProduct} />
      </div>
    </div>
  );
}
