import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

//
import "../../css/tablecalc.css";
// components
import Loja from "../Loja";
import Typography from "@material-ui/core/Typography";
// utils
import { formatCurrency } from "../../utils/currency";
import IconButton from "@material-ui/core/IconButton";
// icons
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

const tableHead = {
  fontWeight: "bold",
};

function createData(col1, col2) {
  return { col1, col2 };
}

export default function DenseTable(props) {
  const {
    item: { storeProducts },
  } = props;

  const classes = useStyles();

  const data = [
    { index: "0", produtos: "Staubli conector MC4 acoplador fêmea" },
    { index: "1", produtos: "Staubli conector MC4 acoplador macho" },
    { index: "2", produtos: "String Box ProAuto quadro 4 entradas/2saídas" },
    { index: "3", produtos: "Inversores solar REFUSOL 7.5Kw Monofásico 220V" },
    {
      index: "4",
      produtos: "Painéis solares BYD 335W Policristalino 17% eficiência",
    },
    {
      index: "5",
      produtos: "Estruturas ROMAGNOLE perfil de alumínio 4,15m/ 4 painéis",
    },
    { index: "6", produtos: "Estruturas ROMAGNOLE painéis fixadores Z" },
    { index: "7", produtos: "Cabos Nexans Energyflex 0,6/1KV 1500V DC Preto" },
    {
      index: "8",
      produtos: "Cabos Nexans Energyflex 0,6/1KV 1500V DC Vermelho",
    },
  ];

  const rows = [
    createData(
      <input
        style={({ textAlign: "left" }, tableHead)}
        className="calcinput"
        label=""
        name="produtodescricao"
        onChange={(e) => props.onChange(e)}
        value={
          props.item.produtodescricao === ""
            ? "PRODUTOS"
            : props.item.produtodescricao
        }
      />,
      <input
        style={tableHead}
        className="calcinput"
        label=""
        name="produtomaterial"
        onChange={(e) => props.onChange(e)}
        value={
          props.item.produtomaterial === ""
            ? "QUANTIDADE"
            : props.item.produtomaterial
        }
      />
    ),
    ...data.map((item) =>
      createData(
        <input
          style={{ textAlign: "left" }}
          className="calcinput"
          label=""
          name={`produtodescricao${item.index}`}
          onChange={(e) => props.onChange(e)}
          value={
            props.item[`produtodescricao${item.index}`] === ""
              ? item.produtos
              : props.item[`produtodescricao${item.index}`]
          }
        />,
        <input
          className="calcinput"
          label=""
          name={`produtomaterial${item.index}`}
          onChange={(e) => props.onChange(e)}
          value={
            props.item[`produtomaterial${item.index}`] === ""
              ? ""
              : props.item[`produtomaterial${item.index}`]
          }
        />
      )
    ),
  ];

  const productRows = [
    createData(
      <input
        style={({ textAlign: "left" }, tableHead)}
        label=""
        name="productDescription"
        onChange={(e) => props.onChange(e)}
        value={"PRODUTOS"}
      />,
      <input
        style={tableHead}
        label=""
        name="productPrice"
        onChange={(e) => props.onChange(e)}
        value={"PREÇO"}
      />
    ),
    ...storeProducts.map((item, index) =>
      createData(
        <input
          style={{ textAlign: "left", width: "100%" }}
          label=""
          name={`productDescription${index}`}
          value={item.product}
        />,
        <input
          label=""
          name={`productPrice${index}`}
          onChange={(e) => props.onChange(e)}
          value={formatCurrency(item.price)}
        />
      )
    ),
  ];

  const table = {
    backgroundColor: "#fff",
  };

  return (
    <div className={classes.root}>
      <div className="non-printable">
        <Typography variant="h5" gutterBottom>
          Loja
        </Typography>
        <Loja handleAddProduct={props.handleAddProduct} />
        <Typography variant="h5" gutterBottom>
          Materiais
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small" style={table}>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.col1}>
                <TableCell component="th" scope="row">
                  {row.col1}
                </TableCell>
                <TableCell align="center">{row.col2}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <div className="non-printable">
        <Typography variant="h5" gutterBottom>
          Equipamentos
        </Typography>
        <Paper className={classes.paper}>
          <Table className={classes.table} size="small" style={table}>
            <TableBody>
              {productRows.map((row, index) => (
                <TableRow key={row.col1}>
                  <TableCell className="table-66" component="th" scope="row">
                    {row.col1}
                  </TableCell>
                  <TableCell className="table-33" align="center">
                    {row.col2}
                  </TableCell>
                  {index > 0 ? (
                    <TableCell
                      className="table-33 non-printable"
                      align="center"
                    >
                      <Tooltip title="Remover" aria-label="remover">
                        <IconButton
                          aria-label="add"
                          className={classes.margin}
                          size="small"
                          onClick={() => props.handleRemoveProduct(index - 1)}
                        >
                          <RemoveCircleIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  );
}
