import React, { useState, useEffect } from "react";
import auth from "./auth";
// MUI
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Paper } from "@material-ui/core";
//
import { Button, Form } from "reactstrap";
//
import AppIcon from "./image/logo.png";
//
import axios from "axios";

const useStyles = makeStyles({
  form: {
    marginTop: 20,
    textAlign: "center",
  },
  avatar: {
    width: 220,
    height: 220,
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    margin: "20px auto 10px auto",
    position: "relative",
    backgroundColor: "rgb(253, 193, 16)",
    color: "rgb(52, 56, 105)",
    fontWeight: "bold",
  },
  customError: {
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progress: {
    position: "absolute",
  },
  paper: {
    padding: 12,
    color: "rgb(52, 56, 105)",
  },
  link: {
    marginTop: 20,
  },
});

export const LandingPage = (props) => {
  const classes = useStyles();

  const [errors, setErrors] = useState({});

  const [state, setState] = useState({
    username: "",
    password: "",
    username_err: "",
    password_err: "",
  });

  // Handle Change
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      username_err: "",
      password_err: "",
    });
  };

  const submitFormLogin = (e) => {
    e.preventDefault();
    axios
      .post(`https://app.schimiths.com.br/api/login.php`, {
        username: state.username,
        password: state.password,
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.success) {
          // props.addItemToState(item);
          setState({ ...state, ...data });
          auth.login(() => {
            props.history.push("/app");
          }, state.username);
          // props.toggle();
        } else {
          setState({ ...state, ...data });
        }
      });
  };

  return (
    <Form onSubmit={(e) => submitFormLogin(e)}>
      <Grid container className={classes.form}>
        <Grid item sm xs />
        <Grid item sm xs={10}>
          <Paper className={classes.paper}>
            <Avatar src={AppIcon} className={classes.avatar} />

            <Typography
              variant="h4"
              align="center"
              className={classes.pageTitle}
            >
              Login
            </Typography>

            {/* <form noValidate> */}
            <TextField
              id="username"
              name="username"
              type="username"
              label="Nome"
              className={classes.textField}
              helperText={state.username_err ? state.username_err : false}
              error={state.username_err ? true : false}
              value={state.username}
              onChange={(e) => onChange(e)}
              fullWidth
            />

            <TextField
              id="password"
              name="password"
              type="password"
              label="Password"
              className={classes.textField}
              helperText={state.password_err ? state.password_err : false}
              error={state.password_err ? true : false}
              value={state.password}
              onChange={(e) => onChange(e)}
              fullWidth
            />
            {errors.general && (
              <Typography variant="body2" className={classes.customError}>
                {errors.general}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              // onClick={() => {
              //   auth.login(() => {
              //     props.history.push("/app");
              //   });
              // }}
            >
              Login
            </Button>
            <br />
            {/* </form> */}
          </Paper>
        </Grid>
        <Grid item sm xs />
      </Grid>
    </Form>
  );
};
