import React, { Component } from "react";
import axios from "axios";
import api from "../../service/api";
// COMPONENTS
import LojaView from "./loja-view";

class LojaContainer extends Component {
  state = { dados: [] };
  componentDidMount() {
    api
      .post("/service.php", { request: "getFilter" })
      .then((res) => {
        this.setState({
          dados: res.data.Dados.filter(
            (dado) =>
              dado.Descricao === "INVERSOR SOLAR" ||
              dado.Descricao === "PAINEL SOLAR" ||
              dado.Descricao === "POTÊNCIA DO GERADOR (KWP)" ||
              dado.Descricao === "ESTRUTURA"
          ),
        });
      })
      .catch((err) => console.log(err));
  }
  render() {
    return <LojaView dados={this.state.dados} {...this.props} />;
  }
}

export default LojaContainer;
