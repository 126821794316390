import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import ModalForm from "./Components/Modals/Modal";
import DataTable from "./Components/Tables/DataTable";
import auth from "./auth";
// MUI
import { Button } from "@material-ui/core";

const button = {
  position: "relative",
  margin: "20px 10px 10px 10px",
  height: "38px",
  backgroundColor: "rgb(253, 193, 16)",
  color: "rgb(52, 56, 105)",
  fontWeight: "bold",
};
class App extends Component {
  state = {
    items: [],
  };

  async getItems() {
    await fetch("https://app.schimiths.com.br/api/quote/read.php")
      .then((response) => response.json())
      .then((response) => {
        return response.data;
      })
      .then((items) => {
        this.setState({
          items,
        });
      })
      .catch((err) => console.log(err));
  }

  addItemToState = (item) => {
    this.setState((prevState) => ({
      items: prevState.items ? [...prevState.items, item] : [item],
    }));
  };

  updateState = (item) => {
    const itemIndex = this.state.items.findIndex((data) => data.id === item.id);
    const newArray = [
      // destructure all items from beginning to the indexed item
      ...this.state.items.slice(0, itemIndex),
      // add the updated item to the array
      item,
      // add the rest of the items to the array from the index after the replaced item
      ...this.state.items.slice(itemIndex + 1),
    ];
    this.setState({ items: newArray });
  };

  deleteItemFromState = (id) => {
    const updatedItems = this.state.items.filter((item) => item.id !== id);
    this.setState({ items: updatedItems });
  };

  componentDidMount() {
    this.getItems();
  }

  styles = {
    title: {
      margin: "20px 0px",
    },
  };

  render() {
    return (
      <Container className="App">
        <Row>
          <Col>
            <h1 style={this.styles.title}>Orçamentos</h1>
          </Col>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={button}
            onClick={() => {
              auth.logout(() => {
                this.props.history.push("/");
              });
            }}
          >
            Sair
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={button}
            onClick={() => {
              this.props.history.push("/reset");
            }}
          >
            Trocar Senha
          </Button>
        </Row>
        <Row id="modal-row">
          <Col>
            <ModalForm
              buttonLabel="Criar Orçamento"
              addItemToState={this.addItemToState}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.items ? (
              <DataTable
                items={this.state.items}
                updateState={this.updateState}
                deleteItemFromState={this.deleteItemFromState}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
        {/* <Row id="modal-row">
          <Col>
            <ModalForm
              buttonLabel="Criar Orçamento"
              addItemToState={this.addItemToState}
            />
          </Col>
        </Row> */}
      </Container>
    );
  }
}

export default App;
