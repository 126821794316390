import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
//
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CurrencyInput from "react-currency-masked-input";
//
import Textfield from "./Textfield";
import TableCalc from "./TableCalc";
import BillOfMaterials from "./BillOfMaterials";
import Table3Col from "./Table3Col";
import Table2Col from "./Table2Col";
import TableSchedule from "./TableSchedule";
import TableScheduleRow from "./TableScheduleRow";
import Barchart from "./Barchart";
import SolarRadiation from "./SolarRadiation";
//
import Typography from "@material-ui/core/Typography";
import { Button, Form } from "reactstrap";
import axios from "axios";
//
import "../../css/print.css";
//
import { citys } from "./citys";
import { formatCurrency } from "../../utils/currency";

// https://www.schimiths.com.br/app/api

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

export default function FullWidthGrid(props) {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const paper = {
    fontSize: "15px",
    textAlign: "left",
    backgroundColor: "#189ebc0d",
    color: "#343869",
    marginBottom: 20,
  };

  const title = {
    fontWeight: "bold",
    marginTop: 12,
    fontSize: "20px",
    color: "#343869",
  };

  const subtitle = {
    fontWeight: "bold",
    marginTop: 12,
    fontSize: "20px",
    color: "#343869",
  };

  const header = {
    marginTop: 12,
    fontSize: "20px",
    color: "#F7892A",
  };

  const noMargin = { margin: 0 };

  const margin = {
    marginTop: 30,
    marginBottom: 30,
  };

  const [state, setState] = useState({
    storeProducts: [],
    clientState: "Rio de Janeiro",
    clientCity: "",
    clientOption: "",
    id: 0,
    name: "",
    location: "",
    client_group: "",
    careof: "",
    units: "",
    fase_type: 50,
    kwh: "",
    solar_panels: "",
    janeiro: "",
    fevereiro: "",
    marco: "",
    abril: "",
    maio: "",
    junho: "",
    julho: "",
    agosto: "",
    setembro: "",
    outubro: "",
    novembro: "",
    dezembro: "",
    cell1: "",
    cell2: "5",
    cell3: "",
    cell4: "",
    cell5: "",
    cell6: "",
    cell7: "",
    cell8: "",
    cell9: "",
    cell10: "",
    cell11: "",
    cell12: "",
    cell13: "",
    cell14: "",
    cell15: "",
    cell16: "45",
    cell17: "",
    cell18: "",
    cell19: "",
    cell20: "",
    cell21: "",
    cell22: "",
    cell23: "",
    cell24: "",
    cell25: "",
    cell26: "",
    cell27: "15",
    cell28: "",
    cell29: "",
    cell30: "",
    cell31: "",
    cell32: "",
    cell33: "",
    cell34: "",
    cell35: "",
    cell36: "",
    cell37: "",
    cell38: "",
    cell39: "2",
    cell40: "",
    cell41: "",
    cell42: "",
    cell43: "",
    cell44: "",
    cell45: "",
    cell46: "",
    cell47: "",
    cell48: "",
    cell49: "",
    cell50: "7",
    mes: "",
    investimento: "",
    equipment: "",
    labor: "",
    equipment: "",
    labor: "",
    rodape_meio: "",
    rodape_direita: "",
    parcelas: "",
    x24: "",
    x36: "",
    x48: "",
    x60: "",
    modulo: "",
    inversores: "",
    estruturas: "",
    cabos: "",
    sch_x6: "",
    crt_x6: "",
    crt_x12: "",
    disjuntor: "",
    rodape_meio_title: "",
    rodape_meio_text: "",
    average_power_consumption: "",
    radiacaojaneiro: "",
    radiacaofevereiro: "",
    radiacaomarco: "",
    radiacaoabril: "",
    radiacaomaio: "",
    radiacaojunho: "",
    radiacaojulho: "",
    radiacaoagosto: "",
    radiacaosetembro: "",
    radiacaooutubro: "",
    radiacaonovembro: "",
    radiacaodezembro: "",
    produtodescricao: "",
    produtodescricao0: "",
    produtodescricao1: "",
    produtodescricao2: "",
    produtodescricao3: "",
    produtodescricao4: "",
    produtodescricao5: "",
    produtodescricao6: "",
    produtodescricao7: "",
    produtodescricao8: "",
    produtomaterial: "",
    produtomaterial0: "",
    produtomaterial1: "",
    produtomaterial2: "",
    produtomaterial3: "",
    produtomaterial4: "",
    produtomaterial5: "",
    produtomaterial6: "",
    produtomaterial7: "",
    produtomaterial8: "",
    banco1: "",
    banco2: "",
    financiamento: "",
    interest: "2",
    interest3x: "2",
    interest12x: "2",
  });

  React.useEffect(() => {
    calcStoreProductsTotal();
  }, [state.storeProducts]);

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const calcStoreProductsTotal = () => {
    const arr = [...state.storeProducts];

    const reducer = (accumulator, currentValue) => ({
      price: accumulator.price + currentValue.price,
    });
    const total = arr.length > 0 ? arr.reduce(reducer) : { price: 0 };
    setState({
      ...state,

      equipment: total.price * 100,
    });
  };

  const handleAddProduct = (storeProduct) => {
    const arr = [...state.storeProducts];
    arr.push({
      product: storeProduct.psg_descricao,
      price: storeProduct.prd_preco,
    });
    const reducer = (accumulator, currentValue) => ({
      price: accumulator.price + currentValue.price,
    });
    const total = arr.length > 0 ? arr.reduce(reducer) : { price: 0 };

    setState({
      ...state,

      storeProducts: arr,
      equipment: total.price * 100,
    });
  };

  const handleRemoveProduct = (index) => {
    let products = [...state.storeProducts];
    products.splice(index, 1);
    setState({ ...state, storeProducts: products });
  };

  const formatNumber = (text) => {
    // somente numeros
    if (text == undefined) {
      return 0;
    }
    if (text == 0) {
      return 0;
    }
    let text1 = text.toString().match(/\d/g)
      ? text.toString().match(/\d/g).join("")
      : "";
    //virgula nos dois ultimos char
    text1 =
      text1.replace(/,/g, "").substring(0, text1.length - 2) +
      "." +
      text1.replace(/,/g, "").substring(text1.length - 2);
    // condicional
    if (text1 == ".") {
      text1 = "";
    }
    //moeda local
    text1 = formatter.format(text1);
    return text1;
  };

  const formatNumberToFloat = (text) => {
    if (text == undefined) {
      return 0;
    }
    if (text == null) {
      return 0;
    }
    return (
      parseFloat(
        text
          .replace(/\./g, "")
          .replace(/\,/g, "")
          .match(/[0-9]+/)
      ).toFixed(2) / 100
    );
  };

  const submitPanelsChange = (solar_panels) => {
    axios
      .post(`https://app.schimiths.com.br/api/quote/panelsupdate.php`, {
        id: state.id,
        solar_panels: solar_panels,
        radiacaojaneiro: state.radiacaojaneiro,
        radiacaofevereiro: state.radiacaofevereiro,
        radiacaomarco: state.radiacaomarco,
        radiacaoabril: state.radiacaoabril,
        radiacaomaio: state.radiacaomaio,
        radiacaojunho: state.radiacaojunho,
        radiacaojulho: state.radiacaojulho,
        radiacaoagosto: state.radiacaoagosto,
        radiacaosetembro: state.radiacaosetembro,
        radiacaooutubro: state.radiacaooutubro,
        radiacaonovembro: state.radiacaonovembro,
        radiacaodezembro: state.radiacaodezembro,
      })
      .then((response) => response.data.success)
      .then((item) => {
        if (item != null) {
          setState({
            ...state,
            system_power: item.system_power,
            solar_panels: item.solar_panels,
            average_power_consumption: item.average_power_consumption,
          });
        }
      });
  };

  const submitFormCalc = (e) => {
    e.preventDefault();
    axios
      .post(`https://app.schimiths.com.br/api/quote/calc.php`, {
        id: state.id,
        name: state.name,
        location: state.location,
        client_group: state.client_group,
        careof: state.careof,
        units: state.units,
        fase_type: state.fase_type,
        kwh: state.kwh,
        consumptions: state.consumptions,
        average_power_consumption: state.average_power_consumption,
        current_average_bill: state.current_average_bill,
        solar_panels: state.solar_panels,
        system_power: state.system_power,
        solar_average_bill: state.solar_average_bill,
        installation_area: state.installation_area,
        janeiro: state.janeiro,
        fevereiro: state.fevereiro,
        marco: state.marco,
        abril: state.abril,
        maio: state.maio,
        junho: state.junho,
        julho: state.julho,
        agosto: state.agosto,
        setembro: state.setembro,
        outubro: state.outubro,
        novembro: state.novembro,
        dezembro: state.dezembro,
        cell1: state.cell1,
        cell2: state.cell2,
        cell3: state.cell3,
        cell4: state.cell4,
        cell5: state.cell5,
        cell6: state.cell6,
        cell7: state.cell7,
        cell8: state.cell8,
        cell9: state.cell9,
        cell10: state.cell10,
        cell11: state.cell11,
        cell12: state.cell12,
        cell13: state.cell13,
        cell14: state.cell14,
        cell15: state.cell15,
        cell16: state.cell16,
        cell17: state.cell17,
        cell18: state.cell18,
        cell19: state.cell19,
        cell20: state.cell20,
        cell21: state.cell21,
        cell22: state.cell22,
        cell23: state.cell23,
        cell24: state.cell24,
        cell25: state.cell25,
        cell26: state.cell26,
        cell27: state.cell27,
        cell28: state.cell28,
        cell29: state.cell29,
        cell30: state.cell30,
        cell31: state.cell31,
        cell32: state.cell32,
        cell33: state.cell33,
        cell34: state.cell34,
        cell35: state.cell35,
        cell36: state.cell36,
        cell37: state.cell37,
        cell38: state.cell38,
        cell39: state.cell39,
        cell40: state.cell40,
        cell41: state.cell41,
        cell42: state.cell42,
        cell43: state.cell43,
        cell44: state.cell44,
        cell45: state.cell45,
        cell46: state.cell46,
        cell47: state.cell47,
        cell48: state.cell48,
        cell49: state.cell49,
        cell50: state.cell50,
        mes: state.mes,
        investimento: state.investimento,
        equipment: state.equipment,
        labor: state.labor,
        rodape_meio: state.rodape_meio,
        rodape_direita: state.rodape_direita,
        parcelas: state.parcelas,
        x24: state.x24,
        x36: state.x36,
        x48: state.x48,
        x60: state.x60,
        modulo: state.modulo,
        inversores: state.inversores,
        estruturas: state.estruturas,
        cabos: state.cabos,
        sch_x6: state.sch_x6,
        crt_x6: state.crt_x6,
        crt_x12: state.crt_x12,
        disjuntor: state.disjuntor,
        rodape_meio_title: state.rodape_meio_title,
        rodape_meio_text: state.rodape_meio_text,
        radiacaojaneiro: state.radiacaojaneiro,
        radiacaofevereiro: state.radiacaofevereiro,
        radiacaomarco: state.radiacaomarco,
        radiacaoabril: state.radiacaoabril,
        radiacaomaio: state.radiacaomaio,
        radiacaojunho: state.radiacaojunho,
        radiacaojulho: state.radiacaojulho,
        radiacaoagosto: state.radiacaoagosto,
        radiacaosetembro: state.radiacaosetembro,
        radiacaooutubro: state.radiacaooutubro,
        radiacaonovembro: state.radiacaonovembro,
        radiacaodezembro: state.radiacaodezembro,
        produtodescricao: state.produtodescricao,
        produtodescricao0: state.produtodescricao0,
        produtodescricao1: state.produtodescricao1,
        produtodescricao2: state.produtodescricao2,
        produtodescricao3: state.produtodescricao3,
        produtodescricao4: state.produtodescricao4,
        produtodescricao5: state.produtodescricao5,
        produtodescricao6: state.produtodescricao6,
        produtodescricao7: state.produtodescricao7,
        produtodescricao8: state.produtodescricao8,
        produtomaterial: state.produtomaterial,
        produtomaterial0: state.produtomaterial0,
        produtomaterial1: state.produtomaterial1,
        produtomaterial2: state.produtomaterial2,
        produtomaterial3: state.produtomaterial3,
        produtomaterial4: state.produtomaterial4,
        produtomaterial5: state.produtomaterial5,
        produtomaterial6: state.produtomaterial6,
        produtomaterial7: state.produtomaterial7,
        produtomaterial8: state.produtomaterial8,
        banco1: state.banco1,
        banco2: state.banco2,
        financiamento: state.financiamento,
        interest: state.interest,
        interest: state.interest,
        interest3x: state.interest3x,
        interest12x: state.interest12x,
      })
      .then((response) => response.data.success)
      .then((item) => {
        if (item != null) {
          setState({ ...state, ...item });
        }
      });
  };

  const submitFormAdd = (e) => {
    e.preventDefault();
    axios
      .post(`https://app.schimiths.com.br/api/quote/create.php`, {
        name: state.name,
        location: state.location,
        client_group: state.client_group,
        careof: state.careof,
        units: state.units,
        fase_type: state.fase_type,
        kwh: state.kwh,
        consumptions: state.consumptions,
        average_power_consumption: state.average_power_consumption,
        current_average_bill: state.current_average_bill,
        solar_panels: state.solar_panels,
        system_power: state.system_power,
        solar_average_bill: state.solar_average_bill,
        installation_area: state.installation_area,
        janeiro: state.janeiro,
        fevereiro: state.fevereiro,
        marco: state.marco,
        abril: state.abril,
        maio: state.maio,
        junho: state.junho,
        julho: state.julho,
        agosto: state.agosto,
        setembro: state.setembro,
        outubro: state.outubro,
        novembro: state.novembro,
        dezembro: state.dezembro,
        cell1: state.cell1,
        cell2: state.cell2,
        cell3: state.cell3,
        cell4: state.cell4,
        cell5: state.cell5,
        cell6: state.cell6,
        cell7: state.cell7,
        cell8: state.cell8,
        cell9: state.cell9,
        cell10: state.cell10,
        cell11: state.cell11,
        cell12: state.cell12,
        cell13: state.cell13,
        cell14: state.cell14,
        cell15: state.cell15,
        cell16: state.cell16,
        cell17: state.cell17,
        cell18: state.cell18,
        cell19: state.cell19,
        cell20: state.cell20,
        cell21: state.cell21,
        cell22: state.cell22,
        cell23: state.cell23,
        cell24: state.cell24,
        cell25: state.cell25,
        cell26: state.cell26,
        cell27: state.cell27,
        cell28: state.cell28,
        cell29: state.cell29,
        cell30: state.cell30,
        cell31: state.cell31,
        cell32: state.cell32,
        cell33: state.cell33,
        cell34: state.cell34,
        cell35: state.cell35,
        cell36: state.cell36,
        cell37: state.cell37,
        cell38: state.cell38,
        cell39: state.cell39,
        cell40: state.cell40,
        cell41: state.cell41,
        cell42: state.cell42,
        cell43: state.cell43,
        cell44: state.cell44,
        cell45: state.cell45,
        cell46: state.cell46,
        cell47: state.cell47,
        cell48: state.cell48,
        cell49: state.cell49,
        cell50: state.cell50,
        mes: state.mes,
        investimento: state.investimento,
        equipment: state.equipment,
        labor: state.labor,
        rodape_meio: state.rodape_meio,
        rodape_direita: state.rodape_direita,
        parcelas: state.parcelas,
        x24: state.x24,
        x36: state.x36,
        x48: state.x48,
        x60: state.x60,
        modulo: state.modulo,
        inversores: state.inversores,
        estruturas: state.estruturas,
        cabos: state.cabos,
        sch_x6: state.sch_x6,
        crt_x6: state.crt_x6,
        crt_x12: state.crt_x12,
        disjuntor: state.disjuntor,
        rodape_meio_title: state.rodape_meio_title,
        rodape_meio_text: state.rodape_meio_text,
        radiacaojaneiro: state.radiacaojaneiro,
        radiacaofevereiro: state.radiacaofevereiro,
        radiacaomarco: state.radiacaomarco,
        radiacaoabril: state.radiacaoabril,
        radiacaomaio: state.radiacaomaio,
        radiacaojunho: state.radiacaojunho,
        radiacaojulho: state.radiacaojulho,
        radiacaoagosto: state.radiacaoagosto,
        radiacaosetembro: state.radiacaosetembro,
        radiacaooutubro: state.radiacaooutubro,
        radiacaonovembro: state.radiacaonovembro,
        radiacaodezembro: state.radiacaodezembro,
        produtodescricao: state.produtodescricao,
        produtodescricao0: state.produtodescricao0,
        produtodescricao1: state.produtodescricao1,
        produtodescricao2: state.produtodescricao2,
        produtodescricao3: state.produtodescricao3,
        produtodescricao4: state.produtodescricao4,
        produtodescricao5: state.produtodescricao5,
        produtodescricao6: state.produtodescricao6,
        produtodescricao7: state.produtodescricao7,
        produtodescricao8: state.produtodescricao8,
        produtomaterial: state.produtomaterial,
        produtomaterial0: state.produtomaterial0,
        produtomaterial1: state.produtomaterial1,
        produtomaterial2: state.produtomaterial2,
        produtomaterial3: state.produtomaterial3,
        produtomaterial4: state.produtomaterial4,
        produtomaterial5: state.produtomaterial5,
        produtomaterial6: state.produtomaterial6,
        produtomaterial7: state.produtomaterial7,
        produtomaterial8: state.produtomaterial8,
        banco1: state.banco1,
        banco2: state.banco2,
        financiamento: state.financiamento,
        interest: state.interest,
        interest3x: state.interest3x,
        interest12x: state.interest12x,
      })
      .then((response) => response.data.success)
      .then((item) => {
        if (item != null) {
          props.addItemToState(item);
          setState({ ...state, ...item });
          props.toggle();
        }
      });
  };

  const submitFormEdit = (e) => {
    e.preventDefault();

    axios
      .put(`https://app.schimiths.com.br/api/quote/update.php`, {
        id: state.id,
        name: state.name,
        location: state.location,
        client_group: state.client_group,
        careof: state.careof,
        units: state.units,
        fase_type: state.fase_type,
        kwh: state.kwh,
        consumptions: state.consumptions,
        average_power_consumption: state.average_power_consumption,
        current_average_bill: state.current_average_bill,
        solar_panels: state.solar_panels,
        system_power: state.system_power,
        solar_average_bill: state.solar_average_bill,
        installation_area: state.installation_area,
        janeiro: state.janeiro,
        fevereiro: state.fevereiro,
        marco: state.marco,
        abril: state.abril,
        maio: state.maio,
        junho: state.junho,
        julho: state.julho,
        agosto: state.agosto,
        setembro: state.setembro,
        outubro: state.outubro,
        novembro: state.novembro,
        dezembro: state.dezembro,
        cell1: state.cell1,
        cell2: state.cell2,
        cell3: state.cell3,
        cell4: state.cell4,
        cell5: state.cell5,
        cell6: state.cell6,
        cell7: state.cell7,
        cell8: state.cell8,
        cell9: state.cell9,
        cell10: state.cell10,
        cell11: state.cell11,
        cell12: state.cell12,
        cell13: state.cell13,
        cell14: state.cell14,
        cell15: state.cell15,
        cell16: state.cell16,
        cell17: state.cell17,
        cell18: state.cell18,
        cell19: state.cell19,
        cell20: state.cell20,
        cell21: state.cell21,
        cell22: state.cell22,
        cell23: state.cell23,
        cell24: state.cell24,
        cell25: state.cell25,
        cell26: state.cell26,
        cell27: state.cell27,
        cell28: state.cell28,
        cell29: state.cell29,
        cell30: state.cell30,
        cell31: state.cell31,
        cell32: state.cell32,
        cell33: state.cell33,
        cell34: state.cell34,
        cell35: state.cell35,
        cell36: state.cell36,
        cell37: state.cell37,
        cell38: state.cell38,
        cell39: state.cell39,
        cell40: state.cell40,
        cell41: state.cell41,
        cell42: state.cell42,
        cell43: state.cell43,
        cell44: state.cell44,
        cell45: state.cell45,
        cell46: state.cell46,
        cell47: state.cell47,
        cell48: state.cell48,
        cell49: state.cell49,
        cell50: state.cell50,
        mes: state.mes,
        investimento: state.investimento,
        equipment: state.equipment,
        labor: state.labor,
        rodape_meio: state.rodape_meio,
        rodape_direita: state.rodape_direita,
        parcelas: state.parcelas,
        x24: state.x24,
        x36: state.x36,
        x48: state.x48,
        x60: state.x60,
        modulo: state.modulo,
        inversores: state.inversores,
        estruturas: state.estruturas,
        cabos: state.cabos,
        sch_x6: state.sch_x6,
        crt_x6: state.crt_x6,
        crt_x12: state.crt_x12,
        disjuntor: state.disjuntor,
        rodape_meio_title: state.rodape_meio_title,
        rodape_meio_text: state.rodape_meio_text,
        radiacaojaneiro: state.radiacaojaneiro,
        radiacaofevereiro: state.radiacaofevereiro,
        radiacaomarco: state.radiacaomarco,
        radiacaoabril: state.radiacaoabril,
        radiacaomaio: state.radiacaomaio,
        radiacaojunho: state.radiacaojunho,
        radiacaojulho: state.radiacaojulho,
        radiacaoagosto: state.radiacaoagosto,
        radiacaosetembro: state.radiacaosetembro,
        radiacaooutubro: state.radiacaooutubro,
        radiacaonovembro: state.radiacaonovembro,
        radiacaodezembro: state.radiacaodezembro,
        produtodescricao: state.produtodescricao,
        produtodescricao0: state.produtodescricao0,
        produtodescricao1: state.produtodescricao1,
        produtodescricao2: state.produtodescricao2,
        produtodescricao3: state.produtodescricao3,
        produtodescricao4: state.produtodescricao4,
        produtodescricao5: state.produtodescricao5,
        produtodescricao6: state.produtodescricao6,
        produtodescricao7: state.produtodescricao7,
        produtodescricao8: state.produtodescricao8,
        produtomaterial: state.produtomaterial,
        produtomaterial0: state.produtomaterial0,
        produtomaterial1: state.produtomaterial1,
        produtomaterial2: state.produtomaterial2,
        produtomaterial3: state.produtomaterial3,
        produtomaterial4: state.produtomaterial4,
        produtomaterial5: state.produtomaterial5,
        produtomaterial6: state.produtomaterial6,
        produtomaterial7: state.produtomaterial7,
        produtomaterial8: state.produtomaterial8,
        banco1: state.banco1,
        banco2: state.banco2,
        financiamento: state.financiamento,
        interest: state.interest,
        interest3x: state.interest3x,
        interest12x: state.interest12x,
      })
      .then((response) => response.data.success)
      .then((item) => {
        if (item != null) {
          props.updateState(item);
          props.toggle();
          setState({ ...state, ...item });
        }
      });
  };

  useEffect(() => {
    if (props.item) {
      setState({ ...state, ...props.item });
    }
  }, []);

  useEffect(() => {
    if (
      state.clientCity != "" &&
      state.clientState != "" &&
      state.clientOption != ""
    ) {
      getRadiation();
    }
  }, [state.clientCity, state.clientState, state.clientOption]);

  // const handlePayment = (e) => {
  //   let total = ""

  //   if (e.target.name == "labor" )
  //    total = formatNumber(parseFloat(formatNumberToFloat(state.equipment) + formatNumberToFloat(e.target.value)).toFixed(2))

  //    if (e.target.name == "equipment" )
  //    total = formatNumber(parseFloat(formatNumberToFloat(e.target.value) + formatNumberToFloat(state.labor)).toFixed(2))

  //      let interest = e.target.name == "interest" ? e.target.value : state.interest
  //       total = e.target.name == "interest" ? state.investimento : total

  //   setState({
  //     ...state,
  //     [e.target.name]: e.target.value,
  //     investimento:total,
  //     x24: calcPayment(total, 24, interest),
  //     x36: calcPayment(total, 36, interest),
  //     x48: calcPayment(total, 48, interest),
  //     x60: calcPayment(total, 60, interest)
  //   })

  // }

  const handlePayment = (e) => {
    let total = "";

    if (e.target.name == "labor")
      total = formatNumber(
        parseFloat(
          formatNumberToFloat(state.equipment) +
            formatNumberToFloat(e.target.value)
        ).toFixed(2)
      );

    if (e.target.name == "equipment")
      total = formatNumber(
        parseFloat(
          formatNumberToFloat(e.target.value) + formatNumberToFloat(state.labor)
        ).toFixed(2)
      );

    let interest =
      e.target.name == "interest" ? e.target.value : state.interest;
    let interest3x =
      e.target.name == "interest3x" ? e.target.value : state.interest3x;
    let interest12x =
      e.target.name == "interest12x" ? e.target.value : state.interest12x;

    total =
      e.target.name == "labor" || e.target.name == "equipment"
        ? total
        : state.investimento;

    setState({
      ...state,
      [e.target.name]: e.target.value,
      investimento: total,
      sch_x6: calcPayment(total, 3, interest3x),
      crt_x6: calcPayment(total, 6, interest12x),
      crt_x12: calcPayment(total, 12, interest12x),
      x24: calcPayment(total, 24, interest),
      x36: calcPayment(total, 36, interest),
      x48: calcPayment(total, 48, interest),
      x60: calcPayment(total, 60, interest),
    });
  };

  const calcPayment = (amount, months, interest) => {
    if (interest == 0) {
      return formatNumber(
        parseFloat(formatNumberToFloat(amount) / months).toFixed(2)
      );
    }
    let interest_rate = interest / 100;
    let payment =
      (formatNumberToFloat(amount) /
        (Math.pow(1 + interest_rate, months) - 1)) *
      (Math.pow(1 + interest_rate, months) * interest_rate);
    return formatNumber(parseFloat(payment).toFixed(2));
  };

  const cities = () => {
    let cities_array = [];
    citys["estados"].map(function (brazil_state, index) {
      if (brazil_state.nome == state.clientState) {
        brazil_state.cidades.map((cidade) => {
          cities_array.push(<option value={cidade}>{cidade}</option>);
        });
      }
    });
    return cities_array;
  };

  const handleChangeState = (e) => {
    setState({ ...state, clientState: e.target.value });
  };
  const handleChangeCity = (e) => {
    setState({ ...state, clientCity: e.target.value });
  };
  const handleChangeOption = (e) => {
    setState({ ...state, clientOption: e.target.value });
  };

  const getRadiation = () => {
    axios
      .post(
        `https://app.schimiths.com.br/api/webscrape/getSolarRadiation.php`,
        {
          state: state.clientState,
          city: state.clientCity,
          option: state.clientOption,
        }
      )
      //  {state:"são paulo", city: "santos"})
      .then((res) => {
        setState({
          ...state,
          radiacaojaneiro: res.data[0],
          radiacaofevereiro: res.data[1],
          radiacaomarco: res.data[2],
          radiacaoabril: res.data[3],
          radiacaomaio: res.data[4],
          radiacaojunho: res.data[5],
          radiacaojulho: res.data[6],
          radiacaoagosto: res.data[7],
          radiacaosetembro: res.data[8],
          radiacaooutubro: res.data[9],
          radiacaonovembro: res.data[10],
          radiacaodezembro: res.data[11],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [printFunding, setPrintFunding] = useState("");

  const hideFunding = () => {
    if (state.funding) {
      setState({ ...state, funding: false });
      setPrintFunding("non-printable paint-red");
    } else {
      setState({ ...state, funding: true });
      setPrintFunding("");
    }
  };

  const signature = (
    <div
      style={{
        backgroundImage: "url(http://app.schimiths.com.br/assinatura_v1.PNG)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        height: "68px",
        backgroundSize: "contain",
        marginBottom: "-18px",
        marginTop: "-62px",
        overflow: "visible",
        zIndex: 111111,
      }}
    ></div>
  );

  return (
    <div>
      <div className="page-header printable">
        <Grid alignItems="center" container spacing={3}>
          <Grid item xs={3} sm={3}>
            <img
              src="http://app.schimiths.com.br/logo.png"
              alt="LOGO"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography align="center" variant="h5" style={title}>
              PROPOSTA COMERCIAL
            </Typography>
            <Typography align="center" variant="h5" style={header}>
              ENERGIA SOLAR
            </Typography>{" "}
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography variant="h6" style={header}>
              <input
                className="quote-input quote-input-center"
                label=""
                name="mes"
                onChange={(e) => onChange(e)}
                value={state.mes === null ? "" : state.mes}
              />
            </Typography>
            <Typography align="center" variant="h5" style={header}>
              Schimith's Sun Energy
            </Typography>
          </Grid>
        </Grid>
        <br />
      </div>

      <div
        className="page-footer printable"
        style={{ backgroundColor: "white" }}
      >
        <Grid alignItems="center" container spacing={3}>
          <Grid item xs={3} sm={3}>
            <img
              src="http://app.schimiths.com.br/logo.png"
              alt="LOGO"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            {signature}
            <Typography variant="h6" style={header}>
              <input
                className="quote-input quote-input-center"
                label=""
                name="rodape_meio_title"
                onChange={(e) => onChange(e)}
                value={
                  state.rodape_meio_title == ""
                    ? "Rafael Schimith Righi"
                    : state.rodape_meio_title
                }
              />
            </Typography>
            <Typography variant="body1" style={noMargin}>
              <textarea
                className="quote-input quote-input-center"
                label=""
                name="rodape_meio_text"
                onChange={(e) => onChange(e)}
                value={
                  state.rodape_meio_text == ""
                    ? "Engenheiro / Responsável Técnico \n (22) 9 8150 3076\natendimento@schimiths.com.br\nCrea RJ 2015120996\nCNPJ: 34.085.778/0001-62\nRua José Monteiro Barros, n 26"
                    : state.rodape_meio_text
                }
                rows="6"
              />
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <List dense={true}>
              <ListItem>
                <img
                  className="web"
                  src="http://app.schimiths.com.br/favicon.png"
                  alt="web"
                />
                <ListItemText primary="www.schimiths.com.br" />
              </ListItem>
              <ListItem>
                {/* <ListItemIcon>
                    <Instagram />
                  </ListItemIcon> */}
                <img
                  className="instagram"
                  src="http://app.schimiths.com.br/instagram.png"
                  alt="instagram"
                />
                <ListItemText primary="www.instagram.com/schimithssunenergy" />
              </ListItem>
              <ListItem>
                <img
                  className="facebook"
                  src="http://app.schimiths.com.br/facebook.jpg"
                  alt="facebook"
                />
                <ListItemText primary="www.facebook.com/schimithssunenergy" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>

      <table>
        <thead className="printable">
          <tr>
            <td>
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <Form
                onSubmit={
                  props.item
                    ? (e) => submitFormEdit(e)
                    : (e) => submitFormAdd(e)
                }
              >
                <div className={classes.root} className="page">
                  <Grid
                    className="non-printable"
                    alignItems="center"
                    container
                    spacing={3}
                  >
                    <Grid item xs={3} sm={3}>
                      <img
                        src="http://app.schimiths.com.br/logo.png"
                        alt="LOGO"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography align="center" variant="h5" style={title}>
                        PROPOSTA COMERCIAL
                      </Typography>
                      <Typography align="center" variant="h5" style={header}>
                        ENERGIA SOLAR
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Typography variant="h6" style={header}>
                        <input
                          className="quote-input quote-input-center"
                          label=""
                          name="mes"
                          onChange={(e) => onChange(e)}
                          value={state.mes === null ? "" : state.mes}
                        />
                      </Typography>
                      <Typography align="center" variant="h5" style={header}>
                        Schimith's Sun Energy
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <br></br> */}

                  <Paper className={classes.paper} style={paper}>
                    <Grid container spacing={1}>
                      <Typography variant="h5" style={title} gutterBottom>
                        1. DIMENSIONAMENTO
                      </Typography>{" "}
                      <Grid item xs={12}>
                        <Textfield
                          // required
                          label="Titular:"
                          name="name"
                          onChange={(e) => onChange(e)}
                          value={state.name === null ? "" : state.name}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Textfield
                          label="Local:"
                          name="location"
                          onChange={(e) => onChange(e)}
                          value={state.location === null ? "" : state.location}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Textfield
                          label="Grupo:"
                          name="client_group"
                          onChange={(e) => onChange(e)}
                          value={
                            state.client_group === null
                              ? ""
                              : state.client_group
                          }
                        />{" "}
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Textfield
                          label="Aos cuidados:"
                          name="careof"
                          onChange={(e) => onChange(e)}
                          value={state.careof === null ? "" : state.careof}
                        />{" "}
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Textfield
                          label="Unidades (Ucs):"
                          name="units"
                          onChange={(e) => onChange(e)}
                          value={state.units === null ? "" : state.units}
                        />{" "}
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Textfield
                          required
                          label="kWh:"
                          name="kwh"
                          onChange={(e) => onChange(e)}
                          value={state.kwh === null ? "" : state.kwh}
                        />{" "}
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <FormControl
                          id="fase_type"
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel ref={inputLabel} htmlFor="fase_type">
                            Fase:
                          </InputLabel>
                          <Select
                            native
                            value={
                              state.fase_type === null ? "" : state.fase_type
                            }
                            onChange={(e) => onChange(e)}
                            input={
                              <OutlinedInput
                                name="fase_type"
                                labelWidth={labelWidth}
                                id="fase_type"
                              />
                            }
                          >
                            <option value="" />
                            <option value={30}>Monofásico</option>
                            <option value={50}>Bifásico</option>
                            <option value={100}>Trifásico</option>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <Typography variant="body1" style={subtitle}>
                            Consumo Mensal
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Janeiro:"
                            name="janeiro"
                            onChange={(e) => onChange(e)}
                            value={state.janeiro === null ? "" : state.janeiro}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Fevereiro:"
                            name="fevereiro"
                            onChange={(e) => onChange(e)}
                            value={
                              state.fevereiro === null ? "" : state.fevereiro
                            }
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Março:"
                            name="marco"
                            onChange={(e) => onChange(e)}
                            value={state.marco === null ? "" : state.marco}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            label="Abril:"
                            name="abril"
                            onChange={(e) => onChange(e)}
                            value={state.abril === null ? "" : state.abril}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Maio:"
                            name="maio"
                            onChange={(e) => onChange(e)}
                            value={state.maio === null ? "" : state.maio}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Junho:"
                            name="junho"
                            onChange={(e) => onChange(e)}
                            value={state.junho === null ? "" : state.junho}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Julho:"
                            name="julho"
                            onChange={(e) => onChange(e)}
                            value={state.julho === null ? "" : state.julho}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Agosto:"
                            name="agosto"
                            onChange={(e) => onChange(e)}
                            value={state.agosto === null ? "" : state.agosto}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Setembro:"
                            name="setembro"
                            onChange={(e) => onChange(e)}
                            value={
                              state.setembro === null ? "" : state.setembro
                            }
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Outubro:"
                            name="outubro"
                            onChange={(e) => onChange(e)}
                            value={state.outubro === null ? "" : state.outubro}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Novembro:"
                            name="novembro"
                            onChange={(e) => onChange(e)}
                            value={
                              state.novembro === null ? "" : state.novembro
                            }
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Textfield
                            required
                            label="Dezembro:"
                            name="dezembro"
                            onChange={(e) => onChange(e)}
                            value={
                              state.dezembro === null ? "" : state.dezembro
                            }
                          />
                        </Grid>
                      </Grid>
                      <div className="non-printable">
                        <Typography variant="body1" style={subtitle}>
                          Radiação Solar
                        </Typography>

                        <select
                          onChange={(e) => handleChangeState(e)}
                          style={{ marginRight: "12px" }}
                        >
                          <option value="Rio de Janeiro">Rio de Janeiro</option>
                          {Object.keys(citys["estados"]).map(function (
                            key,
                            index
                          ) {
                            return (
                              <option value={citys["estados"][key]["nome"]}>
                                {citys["estados"][key]["nome"]}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          onChange={(e) => handleChangeCity(e)}
                          style={{ marginRight: "12px" }}
                        >
                          <option value="">Selecione a cidade...</option>
                          {cities()}
                        </select>
                        <select onChange={(e) => handleChangeOption(e)}>
                          <option value="">Selecione os dados...</option>
                          <option value="4">Maior Média Anual</option>
                          <option value="2">Plano Horizontal</option>
                        </select>

                        <SolarRadiation
                          onChange={(e) => onChange(e)}
                          radiation={[
                            {
                              name: "radiacaojaneiro",
                              label: "Janeiro",
                              value: state.radiacaojaneiro,
                            },
                            {
                              name: "radiacaofevereiro",
                              label: "Fevereiro",
                              value: state.radiacaofevereiro,
                            },
                            {
                              name: "radiacaomarco",
                              label: "Março",
                              value: state.radiacaomarco,
                            },
                            {
                              name: "radiacaoabril",
                              label: "Abril",
                              value: state.radiacaoabril,
                            },
                            {
                              name: "radiacaomaio",
                              label: "Maio",
                              value: state.radiacaomaio,
                            },
                            {
                              name: "radiacaojunho",
                              label: "Junho",
                              value: state.radiacaojunho,
                            },
                            {
                              name: "radiacaojulho",
                              label: "Julho",
                              value: state.radiacaojulho,
                            },
                            {
                              name: "radiacaoagosto",
                              label: "Agosto",
                              value: state.radiacaoagosto,
                            },
                            {
                              name: "radiacaosetembro",
                              label: "Setembro",
                              value: state.radiacaosetembro,
                            },
                            {
                              name: "radiacaooutubro",
                              label: "Outubro",
                              value: state.radiacaooutubro,
                            },
                            {
                              name: "radiacaonovembro",
                              label: "Novembro",
                              value: state.radiacaonovembro,
                            },
                            {
                              name: "radiacaodezembro",
                              label: "Dezembro",
                              value: state.radiacaodezembro,
                            },
                          ]}
                        />
                      </div>
                      <Grid className="non-printable" item xs={12}>
                        <Button
                          style={{ backgroundColor: "blue" }}
                          onClick={(e) => submitFormCalc(e)}
                          className="quote-button"
                        >
                          Dimensionar
                        </Button>
                      </Grid>
                    </Grid>
                    <TableCalc
                      id="quotecalc"
                      item={state}
                      onChange={(e) => onChange(e)}
                      panelsChange={(value) => submitPanelsChange(value)}
                    />
                  </Paper>
                </div>
                <div className={classes.root} className="page">
                  <Paper
                    display="block"
                    className={classes.paper}
                    style={paper}
                  >
                    <Typography
                      display="block"
                      variant="h5"
                      style={title}
                      gutterBottom
                    >
                      2. ESCOPO
                    </Typography>
                    <Typography
                      display="block"
                      variant="h6"
                      style={title}
                      gutterBottom
                    >
                      2.1 ESTIMATIVA DE GERAÇÃO
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Barchart
                          display="block"
                          title="Geração"
                          data={[
                            ["Mês", "kWh", { role: "style" }],
                            [
                              "Jan",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaojaneiro.replace(/,/g, ".") *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Fev",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaofevereiro.replace(
                                        /,/g,
                                        "."
                                      ) *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Mar",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaomarco.replace(/,/g, ".") *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Abr",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaoabril.replace(/,/g, ".") *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Mai",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaomaio.replace(/,/g, ".") *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Jun",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaojunho.replace(/,/g, ".") *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Jul",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaojulho.replace(/,/g, ".") *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Ago",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaoagosto.replace(/,/g, ".") *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Set",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaosetembro.replace(
                                        /,/g,
                                        "."
                                      ) *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Out",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaooutubro.replace(/,/g, ".") *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Nov",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaonovembro.replace(
                                        /,/g,
                                        "."
                                      ) *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                            [
                              "Dez",
                              parseInt(
                                state
                                  ? parseFloat(
                                      state.radiacaodezembro.replace(
                                        /,/g,
                                        "."
                                      ) *
                                        30 *
                                        0.83 *
                                        state.system_power
                                    )
                                  : ""
                              ),
                              "#333768",
                            ],
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Paper>

                  <div className="printable">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                  </div>

                  <Paper className={classes.paper} style={paper}>
                    <Grid container spacing={1}>
                      <Typography
                        display="block"
                        variant="h5"
                        style={title}
                        gutterBottom
                      >
                        3. RETORNO FINANCEIRO
                      </Typography>
                      <Grid item xs={12}>
                        <Typography variant="body1" style={title} gutterBottom>
                          Rendimento mês:{" "}
                          {state.investimento.replace(/\./g, "").match(/\d+/) ==
                          null
                            ? 0
                            : parseFloat(
                                ((state.current_average_bill -
                                  state.solar_average_bill) /
                                  state.investimento
                                    .replace(/\./g, "")
                                    .match(/\d+/)
                                    .map(Number)) *
                                  100
                              ).toFixed(2)}
                          %
                        </Typography>

                        <Barchart
                          title=""
                          data={[
                            ["DESPESA MÉDIA MENSAL", "R$", { role: "style" }],
                            [
                              "Despesa Média Mensal - ANTES",
                              parseInt(state ? state.current_average_bill : 0),
                              "#fdc110",
                            ],
                            [
                              "Despesa Média Mensal - DEPOIS",
                              parseInt(state ? state.solar_average_bill : 0),
                              "#333768",
                            ],
                          ]}
                          antes={parseFloat(state.current_average_bill).toFixed(
                            2
                          )}
                          depois={parseFloat(state.solar_average_bill).toFixed(
                            2
                          )}
                        />
                        <Typography
                          display="block"
                          variant="body1"
                          style={title}
                          gutterBottom
                        >
                          ECONOMIA PERCENTUAL:{" "}
                          {state
                            ? parseFloat(
                                (1 -
                                  state.solar_average_bill /
                                    state.current_average_bill) *
                                  100
                              ).toFixed(1)
                            : ""}
                          %
                        </Typography>
                        <Typography
                          display="block"
                          variant="body1"
                          style={title}
                          gutterBottom
                        >
                          ECONOMIA ANO:
                          {state
                            ? " " +
                              formatNumber(
                                parseFloat(
                                  state.current_average_bill * 12 -
                                    state.solar_average_bill * 12
                                ).toFixed(2)
                              )
                            : ""}
                          {/* {state
                  ? parseFloat(
                      state.current_average_bill * 12 - state.solar_average_bill * 12 
                    ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  : ""} */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Paper className={classes.paper} style={paper}>
                    <Typography variant="h5" style={title} gutterBottom>
                      4. PRAZOS
                    </Typography>
                    <Typography variant="h5" style={title} gutterBottom>
                      4.1 CRONOGRAMA BÁSICO DA OBRA
                    </Typography>
                    <TableSchedule
                      col1="DESCRIÇÃO"
                      col2="MÊS 1"
                      col3="MÊS 2"
                      col4="MÊS 3"
                    />
                    <TableScheduleRow
                      onChange={(e) => onChange(e)}
                      item={state}
                      col1="PROJETO"
                      col2={state.cell2}
                      col3={state.cell3}
                      col4={state.cell4}
                      col5={state.cell5}
                      col6={state.cell6}
                      col7={state.cell7}
                      col8={state.cell8}
                      col9={state.cell9}
                      col10={state.cell10}
                      name={[
                        "cell1",
                        "cell2",
                        "cell3",
                        "cell4",
                        "cell5",
                        "cell6",
                        "cell7",
                        "cell8",
                        "cell9",
                        "cell0",
                      ]}
                      defaultColor={[
                        "transparent",
                        "#fdc110",
                        "#fdc110",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                      ]}
                    />{" "}
                    <TableScheduleRow
                      onChange={(e) => onChange(e)}
                      item={state}
                      col1="APROVAÇÃO"
                      col2={state.cell12}
                      col3={state.cell13}
                      col4={state.cell14}
                      col5={state.cell15}
                      col6={state.cell16}
                      col7={state.cell17}
                      col8={state.cell18}
                      col9={state.cell19}
                      col10={state.cell20}
                      name={[
                        "cell11",
                        "cell12",
                        "cell13",
                        "cell14",
                        "cell15",
                        "cell16",
                        "cell17",
                        "cell18",
                        "cell19",
                        "cell20",
                      ]}
                      defaultColor={[
                        "transparent",
                        "transparent",
                        "transparent",
                        "#fdc110",
                        "#fdc110",
                        "#fdc110",
                        "#fdc110",
                        "#fdc110",
                        "transparent",
                        "transparent",
                      ]}
                    />{" "}
                    <TableScheduleRow
                      onChange={(e) => onChange(e)}
                      item={state}
                      col1="KIT + FRETE"
                      col2={state.cell22}
                      col3={state.cell23}
                      col4={state.cell24}
                      col5={state.cell25}
                      col6={state.cell26}
                      col7={state.cell27}
                      col8={state.cell28}
                      col9={state.cell29}
                      col10={state.cell30}
                      name={[
                        "cell21",
                        "cell22",
                        "cell23",
                        "cell24",
                        "cell25",
                        "cell26",
                        "cell27",
                        "cell28",
                        "cell29",
                        "cell30",
                      ]}
                      defaultColor={[
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "#fdc110",
                        "#fdc110",
                        "transparent",
                        "transparent",
                      ]}
                    />{" "}
                    <TableScheduleRow
                      onChange={(e) => onChange(e)}
                      item={state}
                      col1="MONTAGEM"
                      col2={state.cell32}
                      col3={state.cell33}
                      col4={state.cell34}
                      col5={state.cell35}
                      col6={state.cell36}
                      col7={state.cell37}
                      col8={state.cell38}
                      col9={state.cell39}
                      col10={state.cell40}
                      name={[
                        "cell31",
                        "cell32",
                        "cell33",
                        "cell34",
                        "cell35",
                        "cell36",
                        "cell37",
                        "cell38",
                        "cell39",
                        "cell40",
                      ]}
                      defaultColor={[
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "#fdc110",
                        "transparent",
                      ]}
                    />{" "}
                    <TableScheduleRow
                      onChange={(e) => onChange(e)}
                      item={state}
                      col1="SISTEMA OPERANDO"
                      col2={state.cell42}
                      col3={state.cell43}
                      col4={state.cell44}
                      col5={state.cell45}
                      col6={state.cell46}
                      col7={state.cell47}
                      col8={state.cell48}
                      col9={state.cell49}
                      col10={state.cell50}
                      name={[
                        "cell41",
                        "cell42",
                        "cell43",
                        "cell44",
                        "cell45",
                        "cell46",
                        "cell47",
                        "cell48",
                        "cell49",
                        "cell50",
                      ]}
                      defaultColor={[
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent",
                        "#fdc110",
                      ]}
                    />
                  </Paper>

                  <div className="printable">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                  </div>

                  <Paper className={classes.paper} style={paper}>
                    <Typography variant="h5" style={title} gutterBottom>
                      5. Lista de Materiais
                    </Typography>
                    <BillOfMaterials
                      id="billofmaterials"
                      item={state}
                      onChange={(e) => onChange(e)}
                      panelsChange={(value) => submitPanelsChange(value)}
                      handleAddProduct={handleAddProduct}
                      handleRemoveProduct={handleRemoveProduct}
                    />
                  </Paper>
                  <Paper className={classes.paper} style={paper}>
                    <Typography variant="h5" style={title} gutterBottom>
                      6. PREÇO
                    </Typography>
                    <div>
                      <Typography
                        onClick={() => hideFunding()}
                        variant="h5"
                        style={(title, { display: "inline-block" })}
                        gutterBottom
                      >
                        6.1 VALOR À VISTA NO ATO DA ASSINATURA DO CONTRATO
                      </Typography>
                      <Button
                        style={{ display: "inline-block", marginTop: "-6px" }}
                        onClick={() => hideFunding()}
                        className="non-printable quote-button"
                      >
                        Financiamento
                      </Button>
                    </div>

                    <div className={printFunding}>
                      <Table2Col
                        item={formatNumber(state.equipment)}
                        name="equipment"
                        onChange={(e) => handlePayment(e)}
                        col1Class="table-66"
                        col2Class="table-33"
                        inputClass="quote-input quote-input-right text-highlight"
                        col1="VALOR DOS EQUIPAMENTOS"
                        col2=""
                      />

                      <Table2Col
                        item={formatNumber(state.labor)}
                        name="labor"
                        onChange={(e) => handlePayment(e)}
                        col1Class="table-66"
                        col2Class="table-33"
                        inputClass="quote-input quote-input-right text-highlight"
                        col1="VALOR DA MÃO DE OBRA"
                        col2=""
                      />
                    </div>
                    <Table2Col
                      item={formatNumber(state.investimento)}
                      name="investimento"
                      onChange={(e) => onChange(e)}
                      col1Class="table-66"
                      col2Class="table-33"
                      inputClass="quote-input quote-input-right text-highlight"
                      col1="VALOR TOTAL DO INVESTIMENTO"
                      col2={formatNumber(state.investimento)}
                    />
                    <Typography variant="h5" style={title} gutterBottom>
                      6.2 PARCELAMENTO SCHIMITH'S SUN ENERGY
                    </Typography>
                    <div style={{ width: 100 }} className="non-printable">
                      <Textfield
                        label="Juros:"
                        name="interest3x"
                        onChange={(e) => handlePayment(e)}
                        value={state.interest3x}
                      />
                    </div>
                    <Table3Col
                      item={formatNumber(state.sch_x6)}
                      name="sch_x6"
                      onChange={(e) => onChange(e)}
                      inputClass="quote-input quote-input-right text-highlight"
                      col1="03X"
                      col2="PARCELAS S/ JUROS"
                      col3={state.sch_x6}
                    />

                    <Typography
                      display="block"
                      variant="h6"
                      style={title}
                      gutterBottom
                    >
                      OU
                    </Typography>
                    <div style={{ width: 100 }} className="non-printable">
                      <Textfield
                        label="Juros:"
                        name="interest12x"
                        onChange={(e) => handlePayment(e)}
                        value={state.interest12x}
                      />
                    </div>
                    <Table3Col
                      item={formatNumber(state.crt_x6)}
                      name="crt_x6"
                      onChange={(e) => onChange(e)}
                      inputClass="quote-input quote-input-right"
                      col1="06X"
                      col2="CARTÃO DE CRÉDITO"
                      col3="R$"
                    />
                    <Table3Col
                      item={formatNumber(state.crt_x12)}
                      name="crt_x12"
                      onChange={(e) => onChange(e)}
                      inputClass="quote-input quote-input-right"
                      col1="ATÉ 12X"
                      col2="CARTÃO DE CRÉDITO"
                      col3="R$"
                    />

                    <Typography variant="h5" style={title} gutterBottom>
                      6.3 VALOR DO FINANCIAMENTO NA INSTITUIÇÃO BANCÁRIA
                    </Typography>
                    <Table2Col
                      item={formatNumber(state.banco1)}
                      name="banco1"
                      onChange={(e) => onChange(e)}
                      col1Class="table-66"
                      col2Class="table-33"
                      inputClass="quote-input quote-input-right text-highlight"
                      col1="BRADESCO"
                      col2=""
                    />
                    <Table2Col
                      item={formatNumber(state.banco2)}
                      name="banco2"
                      onChange={(e) => onChange(e)}
                      col1Class="table-66"
                      col2Class="table-33"
                      inputClass="quote-input quote-input-right text-highlight"
                      col1="SICOOB E DEMAIS"
                      col2=""
                    />
                  </Paper>

                  <div className="printable">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                  </div>

                  <Paper className={classes.paper} style={paper}>
                    <Typography
                      display="block"
                      variant="h6"
                      style={title}
                      gutterBottom
                    >
                      6.4 PARCELAMENTO INSTITUIÇÃO FINANCEIRA (SUJEITO À
                      AVALIAÇÃO DE CRÉDITO)
                    </Typography>
                    <div style={{ width: 100 }} className="non-printable">
                      <Textfield
                        label="Juros:"
                        name="interest"
                        onChange={(e) => handlePayment(e)}
                        value={state.interest}
                      />
                    </div>
                    <Table2Col
                      item={formatNumber(state.parcelas)}
                      name="parcelas"
                      onChange={(e) => onChange(e)}
                      col1Class="table-66"
                      col2Class="table-33"
                      col1="PARCELAS"
                      col2="VALOR PARCELAS"
                    />
                    <Table2Col
                      item={formatNumber(state.x24)}
                      name="x24"
                      onChange={(e) => onChange(e)}
                      col1Class="table-66"
                      col2Class="table-33"
                      inputClass="quote-input quote-input-right"
                      col1="24"
                      col2={state.x24}
                      // col2={formatNumber(parseFloat(formatNumberToFloat(state.investimento) * 2).toFixed(2))}
                    />
                    <Table2Col
                      item={formatNumber(state.x36)}
                      name="x36"
                      onChange={(e) => onChange(e)}
                      col1Class="table-66"
                      col2Class="table-33"
                      inputClass="quote-input quote-input-right"
                      col1="36"
                      // col2=""
                      col2={state.x36}
                    />
                    <Table2Col
                      item={formatNumber(state.x48)}
                      name="x48"
                      onChange={(e) => onChange(e)}
                      col1Class="table-66"
                      col2Class="table-33"
                      inputClass="quote-input quote-input-right"
                      col1="48"
                      // col2=""
                      col2={state.x48}
                    />
                    <Table2Col
                      item={formatNumber(state.x60)}
                      name="x60"
                      onChange={(e) => onChange(e)}
                      col1Class="table-66"
                      col2Class="table-33"
                      inputClass="quote-input quote-input-right text-highlight"
                      col1="60"
                      // col2=""
                      col2={state.x60}
                    />
                  </Paper>

                  <Paper className={classes.paper} style={paper}>
                    <Typography variant="h5" style={title} gutterBottom>
                      7. GARANTIAS
                    </Typography>

                    <Table2Col
                      item={state.modulo}
                      name="modulo"
                      onChange={(e) => onChange(e)}
                      col1Class="table-33"
                      col2Class="table-66"
                      inputClass="quote-input quote-input-center"
                      col1="Módulo Fotovoltaico"
                      col2="12 anos para defeito de fabricação (25 anos 80% de pot. nominal)"
                    />
                    <Table2Col
                      item={state.inversores}
                      name="inversores"
                      onChange={(e) => onChange(e)}
                      col1Class="table-33"
                      col2Class="table-66"
                      inputClass="quote-input quote-input-center"
                      col1="Inversores"
                      col2="5 anos para defeito de fabricação (possibilidade 20 anos com garantia estendida)"
                    />
                    <Table2Col
                      item={state.estruturas}
                      name="estruturas"
                      col1Class="table-33"
                      col2Class="table-66"
                      inputClass="quote-input quote-input-center"
                      col1="Estruturas metálicas"
                      col2="5 anos"
                      onChange={(e) => onChange(e)}
                    />
                    <Table2Col
                      item={state.cabos}
                      name="cabos"
                      col1Class="table-33"
                      col2Class="table-66"
                      inputClass="quote-input quote-input-center"
                      col1="Cabos e Conectores"
                      col2="12 anos"
                      onChange={(e) => onChange(e)}
                    />
                  </Paper>

                  <Paper className={classes.paper} style={paper}>
                    <Typography variant="h5" style={title} gutterBottom>
                      8. VALIDADE DESTA PROPOSTA
                    </Typography>
                    <Table2Col
                      col1="Proposta válida no período do mês:"
                      col2=""
                      inputClass="quote-input quote-input-center"
                      item={state.mes}
                      name="mes"
                      onChange={(e) => onChange(e)}
                    />
                  </Paper>
                  <Button className="non-printable quote-button">Salvar</Button>
                  <Button
                    onClick={() => window.print()}
                    className="non-printable quote-button"
                  >
                    Imprimir
                  </Button>

                  <Grid
                    className="non-printable"
                    alignItems="center"
                    container
                    spacing={3}
                  >
                    <Grid item xs={3} sm={3}>
                      <img
                        src="http://app.schimiths.com.br/logo.png"
                        alt="LOGO"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={5} sm={5}>
                      {signature}
                      <Typography variant="h6" style={header}>
                        <input
                          className="quote-input quote-input-center"
                          label=""
                          name="rodape_meio_title"
                          onChange={(e) => onChange(e)}
                          value={
                            state.rodape_meio_title == ""
                              ? "Rafael Schimith Righi"
                              : state.rodape_meio_title
                          }
                        />
                      </Typography>
                      <Typography variant="body1" style={noMargin}>
                        <textarea
                          className="quote-input quote-input-center"
                          label=""
                          name="rodape_meio_text"
                          onChange={(e) => onChange(e)}
                          value={
                            state.rodape_meio_text == ""
                              ? "Engenheiro / Responsável Técnico \n (22) 9 8150 3076\natendimento@schimiths.com.br\nCrea RJ 2015120996\nCNPJ: 34.085.778/0001-62\nRua José Monteiro Barros, n 26"
                              : state.rodape_meio_text
                          }
                          rows="6"
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <List dense={true}>
                        <ListItem>
                          <img
                            className="web"
                            src="http://app.schimiths.com.br/favicon.png"
                            alt="web"
                          />
                          <ListItemText primary="www.schimiths.com.br" />
                        </ListItem>
                        <ListItem>
                          {/* <ListItemIcon>
                    <Instagram />
                  </ListItemIcon> */}
                          <img
                            className="instagram"
                            src="http://app.schimiths.com.br/instagram.png"
                            alt="instagram"
                          />
                          <ListItemText primary="www.instagram.com/schimithssunenergy" />
                        </ListItem>
                        <ListItem>
                          <img
                            className="facebook"
                            src="http://app.schimiths.com.br/facebook.jpg"
                            alt="facebook"
                          />
                          <ListItemText primary="www.facebook.com/schimithssunenergy" />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
