import axios from "axios";

let baseURL;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  console.log("*** dev");
  baseURL =
    "http://localhost/customers/energiasolar/prototipo-webscrape/backend/api/";
} else {
  // production code
  console.log("*** production");
  baseURL = "https://dev.caiotechservices.com/energiasolar/";
}

const api = axios.create({
  baseURL: baseURL,
  // withCredentials: true,
  //   baseURL: "https://dev.caiotechservices.com/energiasolar/",
  // headers: { "Access-Control-Allow-Origin": "*" },
});

export default api;
