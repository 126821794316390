import React from "react";
import Chart from "react-google-charts";

export default function Barchart(props) {
  const style = {
    padding: "0px 10%"
  };
  const options = {
    vAxis: {
      viewWindow: { min: 0, max: 2 * props.data[1][1] },
      gridlines: {
        color: "gray",
        count: 8
      }
    }
  };

  return (
    (
      <div style={style} className="quotechart">
        {props.antes ? (
          <div className="antes">R${props.antes.replace(/\./g, ",")}</div>
        ) : (
          ""
        )}
        {props.depois ? (
          <div className="depois">R${props.depois.replace(/\./g, ",")}</div>
        ) : (
          ""
        )}
        <Chart
          className="chart"
          chartType="ColumnChart"
          width="100%"
          height="200px"
          data={props.data}
          options={props.antes ? "" : options}
        />
      </div>
    )
  );
}
