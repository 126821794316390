import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//
import { LandingPage } from "./landing.page";
// import { AppLayout } from "./app.layout";
import AppLayout from "./App";
import { Reset } from "./Reset";
import { ProtectedRoute } from "./protected.route";
//
import * as serviceWorker from "./serviceWorker";
import "./css/index.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <ProtectedRoute exact path="/app" component={AppLayout} />
        <ProtectedRoute exact path="/reset" component={Reset} />
        <Route path="*" component={() => "404 NOT FOUND"} />
      </Switch>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
);
