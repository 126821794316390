import React, { useState, useEffect } from "react";
import auth from "./auth";
// MUI
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Paper } from "@material-ui/core";
//
import { Button, Form } from "reactstrap";
//
import AppIcon from "./image/logo.png";
//
import axios from "axios";

const useStyles = makeStyles({
  form: {
    marginTop: 20,
    textAlign: "center",
  },
  avatar: {
    width: 220,
    height: 220,
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    margin: "10px 20px 10px 20px",
    position: "relative",
    backgroundColor: "rgb(253, 193, 16)",
    color: "rgb(52, 56, 105)",
    fontWeight: "bold",
  },
  buttonSecondary: {
    margin: "10px 20px 10px 20px",
    position: "relative",
    backgroundColor: "#212529",
    color: "rgb(253, 193, 16)",
    fontWeight: "bold",
  },

  customError: {
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progress: {
    position: "absolute",
  },
  paper: {
    padding: 12,
    color: "rgb(52, 56, 105)",
    height: "537px",
  },
  link: {
    marginTop: 20,
  },
});

export const Reset = (props) => {
  const classes = useStyles();

  const [errors, setErrors] = useState({});

  const [state, setState] = useState({
    new_password: "",
    confirm_password: "",
    new_password_err: "",
    confirm_password_err: "",
  });

  // Handle Change
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      username_err: "",
      password_err: "",
    });
  };

  const submitFormLogin = (e) => {
    e.preventDefault();
    axios
      .post(`https://app.schimiths.com.br/api/reset.php`, {
        confirm_password: state.confirm_password,
        new_password: state.new_password,
        username: auth.getUsername(),
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.success) {
          setState({ ...state, ...data });
          auth.login(() => {
            props.history.push("/app");
          });
        } else {
          setState({ ...state, ...data });
        }
      });
  };

  return (
    <Form onSubmit={(e) => submitFormLogin(e)}>
      <Grid container className={classes.form}>
        <Grid item sm xs />
        <Grid item sm xs={10}>
          <Paper className={classes.paper}>
            <Avatar src={AppIcon} className={classes.avatar} />

            <Typography
              variant="h4"
              align="center"
              className={classes.pageTitle}
            >
              Trocar Senha
            </Typography>

            {/* <form noValidate> */}
            <TextField
              id="new_password"
              name="new_password"
              type="new_password"
              label="Nova Senha"
              className={classes.textField}
              helperText={
                state.new_password_err ? state.new_password_err : false
              }
              error={state.new_password_err ? true : false}
              value={state.new_password}
              onChange={(e) => onChange(e)}
              fullWidth
            />

            <TextField
              id="confirm_password"
              name="confirm_password"
              type="confirm_password"
              label="Confirmar"
              className={classes.textField}
              helperText={
                state.confirm_password_err ? state.confirm_password_err : false
              }
              error={state.confirm_password_err ? true : false}
              value={state.confirm_password}
              onChange={(e) => onChange(e)}
              fullWidth
            />
            {errors.general && (
              <Typography variant="body2" className={classes.customError}>
                {errors.general}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ float: "right" }}
            >
              Salvar
            </Button>
            <Button
              onClick={() => {
                props.history.push("/app");
              }}
              variant="contained"
              color="primary"
              className={classes.buttonSecondary}
              style={{
                float: "left",
                backgroundColor: "#212529",
                color: "rgb(253, 193, 16)",
              }}
            >
              Voltar
            </Button>
            <br />
          </Paper>
        </Grid>
        <Grid item sm xs />
      </Grid>
    </Form>
  );
};
