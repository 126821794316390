import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Textfield from "./Textfield";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  }
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [{ col1: "", col2: "", col3: "" }];

export default function DenseTable(props) {
  const classes = useStyles();

  const table = {
    backgroundColor: "#fff"
  };

  const [state, setState] = useState(props);
  const onChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  return (
    <div className={classes.root}>
      <Paper style={{ margin: "0px" }} className={classes.paper}>
        <Table className={classes.table} size="small" style={table}>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.name}>
                <TableCell align="center">
                  <input
                    
                    className="quote-input quote-input-center"
                    label=""
                    name="col1"
                    onChange={e => props.onChange(e)}
                    value={props.col1 === null ? "" : props.col1}
                  />
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <input
                    
                    className="quote-input quote-input-center"
                    label=""
                    name="col2"
                    onChange={e => onChange(e)}
                    value={props.col2 === null ? "" : props.col2}
                  />
                </TableCell>
                <TableCell align="center">
                  <input
                    
                    className={props.inputClass}
                    label=""
                    name={props.name}
                    onChange={e => props.onChange(e)}
                    value={props.item === "" ? props.col3 : props.item}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
