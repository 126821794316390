import React from "react";
import List from "./components/List";

const LojaView = (props) => {
  const { dados } = props;
  // return dados.map((dado) => dado.Descricao);
  return <List dados={dados} handleAddProduct={props.handleAddProduct} />;
};

export default LojaView;
