import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Grid from "@material-ui/core/Grid";
// import TextField from "@material-ui/core/TextField";
import TextField from "./Textfield";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  }
}));

export default function App(props) {
  const [state, setState] = useState(props.radiation);

  const classes = useStyles();

  useEffect(() => {
    if (props.radiation) {
      setState(props.radiation);
    }
  }, [props.radiation]);

  return (
    <div>
      <Grid container className={classes.root} spacing={2}>
        {state.map((anObjectMapped, index) => (
          <Grid item xs={2}>
            <TextField
              required
              label={anObjectMapped.label}
              name={anObjectMapped.name}
              onChange={e => props.onChange(e)}
              value={anObjectMapped.value === null ? "" : anObjectMapped.value}
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
